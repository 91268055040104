<template>
    <div class="ts-table flex flex-item flex-column">
        <ts-table-edit ref="table" class="make-table flex-item" :cols="cols" :data="list" :page-param="pageParam" :loading="loading"
                       @handleSort="sort" full :cmds="cmds"
                       @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange">
            <div class="page-right" slot="page">
                <div class="static-data page" v-if="staticData.ownNumber">
                    本人已提交：<div class="red">{{staticData.ownNumber}}</div>审核通过：<div class="red">{{staticData.ownPassNumber}}</div>获得积分：<div class="red">{{staticData.integralNumber}}</div>
                </div>
            </div>
        </ts-table-edit>

        <edit-drawer :show.sync="dialog.show" :loading="dialog.loading" @save="save" v-if="dialog.show" :rich="rich" :dialog="dialog"
                     :title="title + (dialog.edit ? '修改' : '新增')" :cols="cols" :form="dialog.form"></edit-drawer>
    </div>
</template>

<script>
    import TsTableEdit from './TsTableEdit'
    import EditDrawer from './EditDrawer'
    import commonMixin from './commonMixin'
    export default {
        props: ['visitId'],
        components: {TsTableEdit, EditDrawer},
        mixins: [commonMixin],
        data() {
            return {
                api: {
                    page: 'sys/baseArticleCompetitor/queryBaseArticleCompetitorPage',
                    add: 'sys/baseArticleCompetitor/insertBaseArticleCompetitor',
                    modify: 'sys/baseArticleCompetitor/updateBaseArticleCompetitor',
                    del: 'sys/baseArticleCompetitor/deleteById',
                    detail: 'sys/baseArticleCompetitor/findBaseArticleCompetitorById',
                    static: 'sys/baseArticleCompetitor/staticArticle',
                    exist: 'sys/baseArticleCompetitor/haveSame',
                },
                staticCols: [
                    {
                        field: 'departmentNumber',
                        title: '科室',
                        showField: 'department',
                    },
                    {
                        field: 'yearNumber',
                        title: '年份',
                        showField: 'year',
                    },
                ],
                cols: [
                    { key: 'baseArticleCompetitorItemDTOList', showKey: 'products', value: '药品', type: 'multipleDrug', required: true},
                    { key: 'title', value: '标题', required: true, search: true, width: 150 },
                    { key: 'organization', value: '单位机构' },
                    { key: 'publishDate', value: '发布日期', required: true },
                    { key: 'author', value: '作者', search: true },
                    { key: 'articleKeyword', value: '关键字/版本', search: true },
                    { key: 'department', value: '科室 ', required: true, search: true },
                    { key: 'conclusion', value: '结论', type: 'textarea' },
                    { key: 'fileUrl', value: 'PDF文件', type: 'file' },
                    { key: 'failReason', value: '不通过原因', width: 200, hEdit: true },
                    {
                        width: 120,
                        key: 'status',
                        showKey: 'statusName',
                        value: '审核状态',
                        fixed: 'right',
                        hEdit: true
                    },
                ],
                existParam: {
                    colKeys: ['title', 'organization', 'publishDate', 'author'],
                },
                cmds: [
                    {
                        txt: '修改',
                        type: 'primary',
                        event: this.modify,
                        whether(row) {
                            return row.status !== 1
                        },
                    },
                    {
                        txt: '删除',
                        type: 'danger',
                        event: this.del,
                        whether(row) {
                            return row.status !== 1
                        },
                    }
                ],
            }
        },
        watch: {
            'dialog.form.title'() {
                this.isExist()
            },
            'dialog.form.organization'() {
                this.isExist()
            },
            'dialog.form.publishDate'() {
                this.isExist()
            },
            'dialog.form.author'() {
                this.isExist()
            },
        }
    }
</script>